<template>
  <div>
    <AppLoading v-if="isLoading" />
    <AppHeader v-if="!isLoading" />
    <AppAlert v-if="!isLoading" />
    <AppProducts v-if="!isLoading" />
    <AppSecc1 v-if="!isLoading" />
    <AppSecc2 v-if="!isLoading" />
    <AppLocation v-if="!isLoading" />
    <AppBrands v-if="!isLoading" />
    <AppAlertFooter v-if="!isLoading" />
    <AppFooter v-if="!isLoading" />
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppAlert from "./components/AppAlert.vue";
import AppProducts from "./components/AppProducts.vue";
import AppSecc1 from "./components/AppSecc1.vue";
import AppSecc2 from "./components/AppSecc2.vue";
import AppAlertFooter from "./components/AppAlertFooter.vue";
import AppLocation from "./components/AppLocation.vue";
import AppFooter from "./components/AppFooter.vue";
import AppBrands from "./components/AppBrands.vue";
import AppLoading from "./components/AppLoading.vue";

export default {
  components: {
    AppHeader,
    AppAlert,
    AppBrands,
    AppProducts,
    AppSecc1,
    AppSecc2,
    AppLocation,
    AppAlertFooter,
    AppFooter,
    AppLoading,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 4000);
  },
};
</script>
