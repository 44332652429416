<template>
    <section class="" role="AlertFooter" id="AlertFooter">
        <div class="container ppequena" style="margin-bottom: 25%;">
&nbsp;
        </div>
    </section>
</template>

<style>
@media only screen and (max-width: 600px) {
  .ppequena {
    margin-bottom: 60% !important;
  }
}
</style>