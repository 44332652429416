<template>
  <div class="container mt-5">
    <p class="fs-2">
      Productos
    </p>
    <hr/>
    <div class="row row-cols-2 row-cols-md-4 g-4">
      <!-- Iterar sobre los productos y mostrarlos -->
      <div
        v-for="(product, index) in products"
        :key="index"
        class="col">
      
        <div class="card h-100">
          <img
            :src="product.image"
            class="card-img-top"
            alt="Producto"
            data-bs-toggle="modal"
            :data-bs-target="'#exampleModal' + index"
            @click="showDescription(index)"
          />
          <div class="card-body">
            <p class="card-title fs-6">{{ product.name }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      v-for="(product, index) in products"
      :key="index"
      class="modal fade"
      :id="'exampleModal' + index"
      tabindex="-1"
      :aria-labelledby="'exampleModalLabel' + index"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title fs-1" :id="'exampleModalLabel' + index">{{ product.name }}</p>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center">
            <img :src="product.image" class="img-thumbnail" alt="{{ product.name }}" />
            <div class="text-start">
              <small v-html="product.description"></small>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success" data-bs-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [
      {
          name: "Queso Gouda",
          image: "xldry-gouda.webp",
          description: `
          <p class='fs-4'>
            
          </p>
          <p class='fs-4'> Presentación:</p>
          <ul>
            <li>
                <p class='fs-5'>
                  Barras de 3kg aprox.
                </p>
            </li>
          </ul>
          `
        },
        {
          name: "Queso Crema Barra 1,3Kg",
          image: "xldry-queso-crema.webp",
          description: `
          <p class='fs-4'>
            Elaborado con la mejor calidad de leche y nata, cuidadosamente cuajado mediante cultivos lácticos. Este queso se destaca por su exquisita untuosidad y su equilibrado perfil de sabor.
          </p>
          <p class='fs-4'> Presentación:</p>
          <ul>
            <li>
                <p class='fs-5'>
                  Barras de 1,3kg aprox.
                </p>
            </li>
          </ul>
          `
        },
        {
          name: "Queso Crema Caja 8Kg",
          image: "xldry-queso-crema-caja.webp",
          description: `
          <p class='fs-4'>
            Elaborado con la mejor calidad de leche y nata, cuidadosamente cuajado mediante cultivos lácticos. Este queso se destaca por su exquisita untuosidad y su equilibrado perfil de sabor.
          </p>
          <p class='fs-4'> Presentación:</p>
          <ul>
            <li>
                <p class='fs-5'>
                  Caja con 8Kg aprox. Ideal para cocinas de alto consunmo como restaurantes y hoteles.
                </p>
            </li>
          </ul>
          `,
        },
        {
          name: "Queso Crema Caja 8Kg",
          image: "xldry-maasdam.webp",
          description: `          
          <p class='fs-4'> Presentación:</p>
          <ul>
            <li>
              <p class='fs-5'>
                Barras de 7kg aproximadamente y piezas partidas que van de 900g a 1kg aproximadamente, empacadas al alto vacío.
                </p>
            </li>
          </ul>`,
        },
        {
          name: "Queso Regginito",
          image: "xldry-parmesano-reggianito.webp",
          description: "Queso reggianito argentino de importación marca <em>La Serenisima</em>. Es la versión argentina del Parmigiano Reggiano que importamos para ti.",
        },
        {
          name: "Queso Mozzarella",
          image: "xldry-mozzarella.webp",
          description: `          
          <p class='fs-4'> Presentación:</p>
          <ul>
            <li>
              <p class='fs-5'>
                Barras de 5kg aproximadamente y piezas partidas de 1,7kg aproximadamente, empacadas al alto vacío.
                </p>
            </li>
          </ul>`,
        },
        {
          name: "WPC (concentrado proteínico de suero)",
          image: "11.webp",
          description: "Producido a partir del suero derivado de la fabricación de quesos, el cual ha sido sometido a los procesos de pasteurización, concentración selectiva mediante procesos de ultrafiltración y secado en spray, para obtener un producto en polvo que contenga máximo el 34% de proteína. Se utiliza para formulas lácteas y producto lácteo combinado.",
        },
        {
          name: "Sólidos de Mantequilla",
          image: "13.webp",
          description: "Producto en polvo elaborado a partir del suero obtenido tras la fabricación de la mantequilla. Se utiliza en productos horneados, postres, panificación, heladería y confitería. Aspecto: polvo fino Color: amarillo crema Olor: libre de olores extraños Sabor: ligero cremoso Humedad: máximo 5.0% Proteína: máximo 30% Grasa: máximo 18% Lactosa: mínimo 41% pH: máximo 6.8 Partículas quemadas: disco B Almidón: negativo Índice de solubilidad: máximo 1.0 mL Cuenta bacteriana: máximo 20,000 UFC/g Coliforme: máximo 5 UFC/g Hongos y levaduras: máximo 1000 UFC/g Escherichia coli: ausente Salmonella: negativo en 25 g Estafiloccocos: ausente",
        },
        {
          name: "Grasa butírica anhidra (AMF)",
          image: "4.webp",
          description: "La grasa butírica anhidra ( AMF para abreviar) es elaborada a partir de crema de leche de alta calidad o mantequilla, donde mediante un proceso físico se extrae el agua y los sólidos no grasos. Contiene mínimo el 99.8% de grasa de leche. Se utiliza en la industria alimentaria para adicionar sabores lácteos a los productos; también se utiliza en productos de panificación, confitería y helados.",
        },
        {
          name: "Suero en Polvo",
          image: "12.webp",
          description: "Producto obtenido a partir del secado del suero. Es usado en productos horneados, heladería, panificación, confitería y fórmulas lácteas. Categoría: materia prima Etiquetas: confiterias, formulas lacteas, heladeria, panificacion, polvo, suero dulce.",
        },
        {
          name: "Lactosa",
          image: "6.webp",
          description: "Principal carbohidrato de la leche. El producto es un polvo fino, que tiene un sabor dulce característico y no deja sabores residuales. Se usa en la elaboración de helados, yogurt, panificación y galletería.",
        },
        {
          name: "Leche Descremada en Polvo",
          image: "7.webp",
          description: "Producto obtenido mediante la deshidratación de la leche descremada. Se usa en la elaboración de helados, recombinación de productos lácteos, confeteria,quesos, choclateria planificación y galletería.",
        },
        {
          name: "Leche Entera en Polvo",
          image: "8.webp",
          description: "Producto obtenido mediante la pasteurización, estandarización y deshidratación de la leche entera. Es utilizada en lácteos, bebidas lácteas, panadería, postres, confitería, bebidas lácteas, etc.",
        },
        {
          name: "MPC 40",
          image: "9.webp",
          description: "Concentrado de proteína de leche (MPC) es una proteína de la leche en polvo, fabricado a partir de leche descremada pasteurizada mediante ultra filtración y osmosis inversa. MPC 40 contiene el 40% de proteína. Se utiliza en la elaboración de quesos frescos, bebidas lácteas, postres, formulas lácteas.",
        },
        {
          name: "MPC 70",
          image: "10.webp",
          description: "Concentrado de proteína de leche (MPC) es una proteína de la leche en polvo soluble, fabricado a partir de leche descremada pasteurizada mediante ultra filtración y osmosis inversa. MPC 70 contiene el 70% de proteína.",
        },
        // Añade el resto de productos aquí (hasta un total de 12)
      ],
      selectedProduct: null,
    };
  },
  methods: {
    showDescription(index) {
      this.selectedProduct = this.products[index];
    },
    closeModal() {
      this.selectedProduct = null;
    },
  },
};
</script>
