<template>
<section class="" id="brands">    
    <div class="container mt-3">
        <div class="row">
            <div class="col">
                <p class="fs-2"> 
                    Nuestras marcas
                </p>
                <hr/>
            </div>
        </div>
    <div class="row">
        <div class="col m-3 p-1 text-end">
            <img src="/logo-DA.webp" alt="" class="img-fluid">
        </div>
        <div class="col m-3 p-1 text-center">
            <img src="/logo-tatua.webp" alt="" class="img-fluid">
        </div>
        <div class="col m-3 p-1 text-start">
            <img src="/logo-saputo.webp" alt="" class="img-fluid">
        </div>
    </div>
</div>

</section>
</template>