<template>
    <section class="" role="compromiso" id="compromiso">
        <div class="container mt-3">
            <div class="row p-1 my-3">
                <div class="col">
                    <p class="fs-2">
                        Compromiso
                    </p>
                    <hr/>
                </div>
            </div>
            <div class="row p-1 my-3">
                <div class="col-sm-1 col-md-4 my-3 p-3">
                    <p class="fs-5">
                        Misión
                    </p>
                    <p class="fs-6">
                        Somos una empresa importadora y comercializadora de la industria alimenticia que proporciona a sus clientes productos lácteos diferenciados de calidad internacional promoviendo así el esquema de ganar-ganar en la cadena productor-comercializador- cliente. 
                    </p>
                </div>
                <div class="col-sm-1 col-md-4 border border-warning bg-light my-3 p-3">
                    <p class="fs-5">
                        Visión
                    </p>
                    <p class="fs-6">
                        Seremos una empresa emprendedora, financieramente sana, de alto desarrollo en la industria alimenticia. Seremos modelo de mejora continua y orgullo de pertenencia de nuestro personal.
                    </p>      
                </div>
                <div class="col-sm-1 col-md-4 my-3 p-3">
                    <p class="fs-5">
                        Política de Calidad
                    </p>
                    <p class="fs-6">
                        Nos esforzamos en proveer materia primas para la industrias alimenticia y en especial para las industrias de lácteos, panificación, pastelería y alta repostería con el fin de cumplir los requisitos del cliente y satisfacer sus necesidades. Aplicaremos recursos, conocimientos y actitudes para trabajar en procesos que reflejen la mejora continua de nuestra organización y de nuestras calidad de vida
                    </p>        
                </div>
            </div>
        </div>
    </section>
</template>