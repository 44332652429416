import { createApp } from 'vue';
import App from './App.vue';
import './bootstrap';
import "@fortawesome/fontawesome-free/css/all.css";
import 'animate.css';


const app = createApp(App);

app.mount('#app');
