<template>
    <section class="" role="location" id="location">
        <div class="container">

            <div class="row">
                <div class="col">
                    <p class="fs-2"> 
                         Ubicación
                    </p>
                    <hr/>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <p class="fs-3 p-1 m-1">
                        Av. Tecamachalco No. 14 Int. 201<br/>
                        Lomas de Chapultepec Secc. VIII<br/>
                        Miguel Hidalgo, 11000 CDMX<br/>
                        México
                    </p>                
                    <p class="fs-3 p-1 m-1">
                        Tel. 55 2623 2324 al 29<br/>
                        contacto@exceldairy.com.mx
                    </p>
                </div>
                <div class="col-sm-12 col-md-6 text-center">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.522200473932!2d-99.2107177!3d19.433039700000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d2012cc7697fb3%3A0x1774bc1bc0a8662b!2sExceldairy%20SA%20de%20CV!5e0!3m2!1ses-419!2smx!4v1690826791779!5m2!1ses-419!2smx" width="100%" height="500px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </section>
</template>