<template>
    <section class="pantalla" role="alert" id="alert" style="margin-top: 28%">

    </section>
  </template>
  
  <style>
  @media only screen and (min-width: 600px) {
    .pantalla {
      margin-top: 14% !important;
    }
  }
  </style>
  