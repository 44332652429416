<template>
  <header class="d-block d-sm-none" id="header" role="header">
    <div class="container-fluid bg-light border shadow">
      <div class="row">
        <div class="col text-start m-3 p-1">
          <img src="logo-vertical.webp" alt="Logotipo EXceldairy SA de CV" class="img navbar-brand" style="width:4rem;">
        </div>
        <div class="col text-center m-3 p-1">
          <a class="btn btn-lg btn-primary" href="https://www.exceldairy.com.mx/pedidos">PEDIDOS</a>
        </div>
      </div>    
    </div>
  </header>
  <header class="fixed-top position-fixed d-none d-sm-block" id="header" role="header">
    <div class="container-fluid fixed-top bg-light border shadow">
      <div class="row">
        <div class="col text-start m-3 p-1">
          <img src="logo.webp" alt="Logotipo EXceldairy SA de CV" class="img navbar-brand" style="width:12rem;">
        </div>
        <div class="col m-3 p-1 text-end">
          <a class="btn btn-lg btn-primary" href="https://www.exceldairy.com.mx/pedidos">Pedidos en Línea</a>
        </div>
      </div>    
    </div>
  </header>
</template>
