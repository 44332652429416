<template>
    <section class="" role="nosotros" id="nosotros">
      <div class="container mt-3">
        <div class="row">
          <div class="col-sm-12 col-md-8">
            <p class="fs-2">
              Nosotros
            </p>
            <hr/>
          </div>
        </div>
        <div class="row p-1 my-3">
          <div class="col d-block d-sm-none">
            <p class="fs-5">
              Desde su fundación en septiembre de 1991 como WMS de México S.A. de C.V., una empresa de origen mexicano, se ha destacado en la comercialización, importación y exportación de materias primas, especialmente para la industria láctea y panadera.<br/><br/>A lo largo de su historia, ha evolucionado y en mayo de 2009 adoptó la denominación Exceldairy S.A. de C.V., marcando un punto de inflexión en su capacidad de adaptación y superación constante, renovando su compromiso con la calidad y su papel en la industria alimentaria. Con un enfoque en las industrias de lácteos, panadería, pastelería y alta repostería, Exceldairy se dedica a proveer materias primas que impulsan la industria alimentaria, reflejando su compromiso con la mejora continua y anticipando un futuro influyente en la cadena de suministro, enriqueciendo su legado de excelencia y adaptabilidad.
            </p>
          </div>
          <div class="col d-none d-sm-block">
            <p class="fs-6 m-1">
              Desde su creación en septiembre de 1991 como WMS de México S.A. de C.V., esta empresa mexicana se ha destacado en la comercialización, importación y exportación de materias primas esenciales para las industrias láctea y panadera.<br/><br/>A lo largo del tiempo, ha evolucionado para convertirse en un pilar fundamental en el abastecimiento de insumos cruciales para la producción alimentaria, demostrando su firme compromiso con la calidad y la innovación.<br/><br/>En mayo de 2009, la empresa experimentó un cambio significativo al adoptar la denominación Exceldairy S.A. de C.V., marcando un hito en su historia y resaltando su capacidad de adaptación y superación constante, mientras renovaba su compromiso con los más altos estándares de calidad y su rol esencial en la industria alimentaria.<br/><br/>
              En la esencia de Exceldairy S.A. de C.V. radica su dedicación inquebrantable a proporcionar materias primas de impulso para las industrias alimentarias, en particular en sectores como lácteos, panadería, pastelería y alta repostería. Más allá de ofrecer productos de calidad, la empresa se destaca por su enfoque en el bienestar y el crecimiento de su equipo, reflejando su compromiso con la mejora constante. Mirando hacia adelante, Exceldairy vislumbra un futuro en el que mantendrá su influencia en la cadena de suministro de la industria alimentaria, enriqueciendo su legado de excelencia y adaptabilidad. Su visión se traduce en seguir siendo un referente en un sector en constante cambio, impulsando la innovación y la calidad en cada paso que da.
            </p>
            <p class="fs-6 m-1">

            </p>
          </div>
          <div class="col-sm-12 col-md-4">
            <img src="/home.webp" class="img-fluid" style="height: 100%; object-fit: contain;"/>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <style>
  @media (min-width: 768px) {
    .custom-desktop-layout {
      display: flex;
      align-items: stretch;
    }
  
    .custom-desktop-layout .col {
      flex: 1;
    }
  
    .custom-desktop-layout img {
      height: 100%;
      object-fit: cover;
    }
  }
  </style>
  