<template>

  <footer class="footer fixed-bottom position-fixed d-block d-md-none">
    <div class="container-fluid bg-light text-center mt-5 py-3">

      <!-- Div abierto -->
      <div v-if="isBusinessOpen" class="row" id="abierto">
        <div class="col text-end">
          <a class="btn btn-lg btn-outline-dark  animate__animated animate__fadeInLeft" href="tel:5526232324">
            <span class="text-dark" style="font-size: 1.2rem;">
              <i class="fa-solid fa-phone"></i>
            </span>
          </a>
        </div>
        <div class="col text-start">
          <a class="btn btn-lg btn-outline-dark  animate__animated animate__fadeInRight" href="https://wa.me/5543810497">
            <span class="text-success" style="font-size: 1.2rem;">
              <i class="fa-brands fa-whatsapp"></i>
            </span>
          </a>
        </div>
      </div>

      <!-- Div cerrado -->
      <div v-else class="row" id="cerrado">
        <div class="col">
          <a class="btn btn-lg btn-outline-dark" href="mailto:contacto@exceldairy.com.mx">
            <span class="" style="font-size: 2em;">
              <i class="fa-solid fa-envelope"></i>
            </span>
          </a>    
        </div>
      </div>

      <div class="row shadow">
        <div class="col bg-light text-center p-1">
          <p style="font-size: .850em;"> Exceldairy SA de CV - &copy; 2009 - {{ currentYear }}</p>
        </div>
      </div>
    </div>
  </footer>

  <footer class="position-static d-none d-md-block">
    <div class="container-fluid bg-light text-center mt-5 py-3">

      <!-- Div abierto -->
      <div v-if="isBusinessOpen" class="row" id="abierto">
        <div class="col text-end">
          <a class="btn btn-lg btn-outline-dark  animate__animated animate__fadeInLeft" href="tel:5526232324">
            <span class="text-dark" style="font-size: 1.2rem;">
              <i class="fa-solid fa-phone"></i>
            </span>
          </a>
        </div>
        <div class="col text-start">
          <a class="btn btn-lg btn-outline-dark  animate__animated animate__fadeInRight" href="https://wa.me/5543810497">
            <span class="text-success" style="font-size: 1.2rem;">
              <i class="fa-brands fa-whatsapp"></i>
            </span>
          </a>
        </div>
      </div>

      <!-- Div cerrado -->
      <div v-else class="row" id="cerrado">
        <div class="col">
          <a class="btn btn-lg btn-outline-dark" href="mailto:contacto@exceldairy.com.mx">
            <span class="" style="font-size: 2em;">
              <i class="fa-solid fa-envelope"></i>
            </span>
          </a>    
        </div>
      </div>

      <div class="row shadow">
        <div class="col bg-light text-center p-1">
          <p style="font-size: .850em;"> Exceldairy SA de CV - &copy; 2009 - {{ currentYear }}</p>
        </div>
      </div>
    </div>
  </footer>



</template>

<script>

import { estaAbierto } from '@/utils.js';

export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    isBusinessOpen() {
      return estaAbierto();
    },
  },
};
</script>

